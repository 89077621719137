<template>
  <el-dialog top="3vh" :visible.sync="showDialog" width="760px">
    <div slot="title" style="text-align: center" class="slot-title">
      <span style="background: #eceafa; padding: 5px">占比设置</span>
    </div>
    <div class="dialog">
      <div class="flex-row flex-as">
        <div class="title">占比标准</div>
        <div class="flex-column">
          <div class="table-remark m-b-10" style="font-size: 18px">
            显示全部数据表选中的参数。
          </div>
          <el-radio-group v-model="radio">
            <el-radio
              v-for="(item, index) in nameList"
              :key="index"
              style="width: 120px"
              :label="index + 1"
              >{{ item }}</el-radio
            >
          </el-radio-group>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button :loading="loading" size="mini" type="primary" @click="saveRadio">保存</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    nameList: {
      default: () => {},
    },
    percentageFlag:Number
  },
  data() {
    return {
      showDialog: false,
      radio: 1,
      loading: false,
    };
  },
  created() {},
  methods: {
    saveRadio() {
      this.showDialog = false
      this.$emit('changePercentFlag', this.radio)
    }
  },
  computed: {},
  watch: {
    showDialog (val) {
      val && (this.radio = this.percentageFlag)
    }
  },
};
</script>

<style scoped lang="scss">
@import "@style/form.scss";
.avatar {
  width: 198px;
  height: 198px;
}
</style>