<template>
  <div class="contatain">
    <div class="tab">
      <!-- tab栏 -->
      <div
        class="tab-item"
        v-for="(item, index) in tabList"
        :key="`a${index}`"
        :class="curTab === item.id ? 'tab-item-active' : ''"
        @click="curTab = index"
      >
        {{ item.name }}
      </div>
    </div>
    <filter-item @dealFilter="dealFilter" />
    <div class="content-wrap">
      <el-card class="row2-card">
        <div class="flex-row flex-as">
          <div class="title">数据表</div>
          <div class="table-remark flex-row">
            <span>注：</span>
            <div>
              1.因微信数据延迟，昨天的部分数据可能未更新；<br />
              2.“整合完整销量”=单一剧本总销售额÷该剧本完整本现价，是把所有单人本凑整为完整本的销量；<br />
              3.“流失用户数”指首次开局中途退出游戏后，近两周没有再登录的用户数。
            </div>
          </div>
        </div>
        <div class="flex-row flex-as">
          <div class="computed-title">计算参数</div>
          <div>
            <div class="flex-row flex-as">
              <el-button
                class="m-r-30 computed-btn"
                :type="checkCheckGroup === 1 ? 'primary' : ''"
                size="mini"
                @click="checkCheckGroup = 1"
                >用户数</el-button
              >
              <el-checkbox-group
                v-model="userQuantityFlagSet"
                style="width: 550px"
              >
                <el-checkbox
                  v-for="(item, index) in usersCheckBoxArr"
                  :key="index"
                  style="width: 100px"
                  :label="index + 1"
                  :disabled="checkCheckGroup !== 1"
                  >{{ item }}</el-checkbox
                >
              </el-checkbox-group>
            </div>
            <div class="flex-row flex-as">
              <el-button
                class="m-r-30 computed-btn"
                :type="checkCheckGroup === 2 ? 'primary' : ''"
                size="mini"
                @click="checkCheckGroup = 2"
                >人次</el-button
              >
              <el-checkbox-group
                v-model="peopleQuantityFlagSet"
                style="width: 550px"
              >
                <el-checkbox
                  v-for="(item, index) in personCheckBoxArr"
                  :key="index"
                  style="width: 100px"
                  :label="index + 1"
                  :disabled="checkCheckGroup !== 2"
                  >{{ item }}</el-checkbox
                >
              </el-checkbox-group>
            </div>
            <div class="flex-row flex-as">
              <el-button
                class="m-r-30 computed-btn"
                :type="checkCheckGroup === 3 ? 'primary' : ''"
                size="mini"
                @click="checkCheckGroup = 3"
                >点券额</el-button
              >
              <el-checkbox-group v-model="couponFlagSet" style="width: 550px">
                <el-checkbox
                  v-for="(item, index) in countCheckBoxArr"
                  :key="index"
                  style="width: 100px"
                  :label="index + 1"
                  :disabled="checkCheckGroup !== 3"
                  >{{ item }}</el-checkbox
                >
              </el-checkbox-group>
            </div>
          </div>
        </div>
        <div class="flex-row flex-as">
          <div class="computed-title">计算方式</div>
          <div class="flex-column flex-1">
            <el-checkbox-group
              v-model="tableFilter.calculateFlagSet"
              style="width: 550px"
            >
              <el-button
                class="m-r-30"
                type="primary"
                size="mini"
                @click="openDialog('proSetting')"
                :disabled="percentSettingButton"
                >设置</el-button
              >
              <el-checkbox
                v-for="(item, index) in computedTypes"
                :key="index"
                style="width: 70px"
                :label="index + 1"
                >{{ item }}</el-checkbox
              >
            </el-checkbox-group>
            <el-table
              border
              :data="tableData"
              :header-cell-style="thStyle"
              :cell-style="cellStyle"
              style="width: auto; flex: 0 1 auto; max-width: 800px"
              class="m-t-10"
              v-loading="loading"
            >
              <el-table-column prop="title" label="" fixed="left" width="80">
              </el-table-column>
              <el-table-column
                v-for="(item, index) in tableHeader"
                :key="index"
                :label="item"
                width="130"
              >
                <template slot-scope="scope">
                  {{ scope.row[`num${index}`]
                  }}{{ scope.row.title === "占比" ? "%" : theUnit }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="flex-row flex-as">
          <div class="title">数据图</div>
          <div class="flex-column">
            <div class="table-remark">显示全部数据表选中的参数。</div>
            <echart-item
              v-loading="loading"
              :data="chartData"
              :lineList="lineList"
              :nameList="nameList"
              dataType="customized"
              :pieData1="pieData1"
              :pieData2="pieData2"
              :pieData3="pieData3"
              :pieData4="pieData4"
              pieDataName1="占比（单位：%）"
              :pieDataName2="`平均值（单位：${theUnit}）`"
              :pieDataName3="`最大值（单位：${theUnit}）`"
              :pieDataName4="`中位数（单位：${theUnit}）`"
              :customizedUnit="theUnit"
            />
          </div>
        </div>
      </el-card>
      <proportion-item
        ref="proSetting"
        :nameList="nameList"
        :percentageFlag="percentageFlag"
        @changePercentFlag="changePercentFlag"
      />
    </div>
  </div>
</template>
<script>
import FilterItem from "./common/component/FilterItem.vue";
import EchartItem from "./common/component/EchartItem.vue";
import ProportionItem from "./common/component/ProportionItem";
import { timestampToDate, debounce } from "@tools";
export default {
  components: {
    FilterItem,
    EchartItem,
    ProportionItem,
  },
  data() {
    return {
      loading: false,
      usersCheckBoxArr: [
        "访问用户数",
        '累计访问用户数',
        "新增用户数",
        "付费用户数",
        "领券用户数",
        "游戏用户数",
        "一局用户数",
        "两局用户数",
        "≥3局用户数",
        "流失用户数",
      ],
      personCheckBoxArr: ["访问人次", "游戏人次", "付费人次"],
      countCheckBoxArr: [
        "剧本销售额",
        "用户购买点券量",
        "用户花费点券量",
        "用户剩余点券量",
      ],
      computedTypes: ["占比", "平均值", "最大值", "中位数"],
      userQuantityFlagSet: [1],
      peopleQuantityFlagSet: [1],
      couponFlagSet: [1],
      checkCheckGroup: 1,
      tabList: [{ name: "自定义", id: 1 }],
      curTab: 1,
      tableFilter: {
        day: 30,
        endTime: timestampToDate(new Date().getTime(), false),
        startTime: timestampToDate(
          new Date().getTime() - 3600 * 1000 * 24 * 30,
          false
        ),
        ignoreFlag: true,
        platform: "",
        calculateFlagSet: [1],
        percentageFlag: 1,
      },
      tableHeader: [],
      tableData: [],
      tableFirstColumn: [],
      chartData: [],
      pieData1: [],
      pieData2: [],
      pieData3: [],
      pieData4: [],
      userPercentageFlag: 1,
      peoplePercentageFlag: 1,
      countPercentageFlag: 1,
    };
  },
  async created() {
    this.getAnalysisDataList(1);
  },
  mounted() {
    const handler = () => this.getAnalysisDataList(this.checkCheckGroup);
    Object.keys(this.tableFilter)
      .filter((_) => !["percentageFlag"].includes(_))
      .forEach((_) => {
        this.$watch((vm) => vm.tableFilter[_], handler, {
          deep: true,
        });
      });
  },
  methods: {
    getAnalysisDataList: debounce(async function (val) {
      this.loading = true;
      let arr = [
          "userQuantityFlagSet",
          "peopleQuantityFlagSet",
          "couponFlagSet",
        ],
        arr2 = ["usersCheckBoxArr", "personCheckBoxArr", "countCheckBoxArr"],
        arr3 = ["percentage", "dayAvgVal", "dayMaxValue", "median"],
        index = val - 1;
      this.tableFilter[arr[index]] = this[arr[index]];
      arr.splice(index, 1);
      arr.forEach((item) => {
        delete this.tableFilter[item];
      });
      this.tableFilter.ignoreMinute = localStorage.ignoreMinute;
      const data = await this.$api.chiguaData
        .getAnalysisDataList(this.tableFilter)
        .finally(() => {
          this.loading = false;
        });
      let { customAnalysisTableVOS, chartDetailVOList } = data;
      // echart数据赋值
      this.chartData = chartDetailVOList;
      // 表格表头
      this.tableHeader = customAnalysisTableVOS.map(
        (item) => this[arr2[this.checkCheckGroup - 1]][item.key - 1]
      );
      // 表格数据
      this.tableData = []; // 表格数据清空
      this.tableData.push({
        // 先把总数组上去，是必须展示的
        title: "总数",
      });
      this.tableFilter.calculateFlagSet.forEach((item) => {
        this.tableData.push({
          title: this.computedTypes[item - 1],
          index: item - 1,
        });
      });
      this.tableData.forEach((tableItem, tableIndex) => {
        customAnalysisTableVOS.forEach((dataItem, dataIndex) => {
          if (tableItem.title === "总数") {
            this.tableData[tableIndex][`num${dataIndex}`] = dataItem.value;
          } else {
            const flag =
              (tableItem.title === "最大值" || tableItem.title === "中位数") &&
              dataIndex === 1;
            this.tableData[tableIndex][`num${dataIndex}`] = flag
              ? "-"
              : dataItem[arr3[tableItem.index]];
          }
        });
      });
      this.getPieData(customAnalysisTableVOS);
    }, 600),
    getPieData(data) {
      this.pieData1 = [];
      this.pieData2 = [];
      this.pieData3 = [];
      this.pieData4 = [];
      data.forEach((item) => {
        let name = this.nameList[item.key - 1];
        this.pieData1.push({
          name,
          value: item.percentage,
          total: item.value,
        });
        this.pieData2.push({
          name,
          value: item.dayAvgVal,
        });
        this.pieData3.push({
          name,
          value: item.dayMaxValue,
        });
        this.pieData4.push({
          name,
          value: item.median,
        });
      });
    },
    dealFilter(e) {
      Object.assign(this.tableFilter, e);
    },
    changePercentFlag(radio) {
      this[this.percentageFlagStr] = radio;
      this.tableFilter.percentageFlag = radio;
      this.getAnalysisDataList(this.checkCheckGroup);
    },
    openDialog(ref) {
      this.$refs[ref].showDialog = true;
    },
    thStyle() {
      return {
        background: "rgba(197, 192, 255, 0.1)",
        color: "#000",
        textAlign: "center",
      };
    },
    cellStyle() {
      return {
        fontWeight: "500",
        color: "#000",
        textAlign: "center",
      };
    },
  },
  watch: {
    checkCheckGroup(val) {
      this.tableFilter.percentageFlag = this.percentageFlag;
      this.getAnalysisDataList(val);
    },
    userQuantityFlagSet: {
      deep: true,
      handler() {
        this.getAnalysisDataList(1);
      },
    },
    peopleQuantityFlagSet: {
      deep: true,
      handler() {
        this.getAnalysisDataList(2);
      },
    },
    couponFlagSet: {
      deep: true,
      handler() {
        this.getAnalysisDataList(3);
      },
    },
    // tableFilter: {
    //   deep: true,
    //   handler() {
    //     // this.getAnalysisDataList(this.checkCheckGroup);
    //   },
    // },
  },
  computed: {
    lineList() {
      if (this.checkCheckGroup === 1) {
        return [
          "访问用户数",
          "新增用户数",
          "付费用户数",
          "领券用户数",
          "游戏用户数",
          "流失用户数",
        ];
      } else if (this.checkCheckGroup === 2) {
        return ["访问人次", "游戏人次"];
      }
      return ["用户购买点券数", "用户花费点券量", "用户剩余点券量"];
    },
    nameList() {
      let names = ["usersCheckBoxArr", "personCheckBoxArr", "countCheckBoxArr"][
        this.checkCheckGroup - 1
      ];
      return this[names];
    },
    theUnit() {
      return ["人", "次", "点券"][this.checkCheckGroup - 1];
    },
    percentageFlag() {
      let precent = [
        "userPercentageFlag",
        "peoplePercentageFlag",
        "countPercentageFlag",
      ][this.checkCheckGroup - 1];
      return this[precent];
    },
    percentageFlagStr() {
      return [
        "userPercentageFlag",
        "peoplePercentageFlag",
        "countPercentageFlag",
      ][this.checkCheckGroup - 1];
    },
    percentSettingButton() {
      return this.tableFilter.calculateFlagSet.indexOf(1) !== 0;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./common/style/dataForm.scss";
@import "./common/style/dataTable.scss";
.item-date {
  text-align: left;
}
</style>