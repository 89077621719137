import { render, staticRenderFns } from "./ProportionItem.vue?vue&type=template&id=4b996cb6&scoped=true"
import script from "./ProportionItem.vue?vue&type=script&lang=js"
export * from "./ProportionItem.vue?vue&type=script&lang=js"
import style0 from "./ProportionItem.vue?vue&type=style&index=0&id=4b996cb6&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b996cb6",
  null
  
)

export default component.exports